import * as React from 'react';

import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import Affiliate from '../Attribution/Affiliate';

// Landing URL: set cookie and redirect to /
const LandingUrl = ({ pageContext }) => {
  return (
    <>
      <Affiliate
        source={pageContext.source}
        affiliate={pageContext.source}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
};

export default LandingUrl;
